import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DocumentService } from 'src/app/core/services/document.service';
import {
  ExtendedFormFreeReportHistory,
  FormFreeHistory,
  LiteAccountInfoReadonlyArray,
  SystemLevel,
  ThirdPartyCredentialType
} from 'src/app/models';
import { ThirdPartyIntegrationProvider } from 'src/app/models/fee/fee.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BorrowerVerificationReportType } from 'src/app/models/voa/borrower-verification-document';
import { BorrowerVerificationRequestType, BorrowerVerificationUserEnrollmentRequest } from 'src/app/models/voa/borrower-verification-user-enrollment-request';
import { GenerateBorrowerVerificationReportRequest } from 'src/app/models/voa/generate-borrower-verification-report-request';
import { UpdateBorrowerVerificationSessionRequest } from 'src/app/models/voa/update-borrower-verification-session-request';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanServicesService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { copyToClipboard } from 'src/utils';
import { ImportAssetsDialogComponent } from '../import-assets-dialog/import-assets-dialog.component';

@Component({
  selector: 'voa-order',
  templateUrl: 'voa-order.component.html',
  styleUrls: ['./voa-order.component.scss'],
})
export class VoaOrderComponent implements OnInit {

  @Input('loanId')
  loanId: number;

  @Input('orders')
  orders: FormFreeHistory[];

  @Input('systemLevel')
  systemLevel: SystemLevel;

  @Output()
  prepareOrdersHistoryToView = new EventEmitter<FormFreeHistory[]>();

  @Output()
  loadHistory = new EventEmitter();

  //todo check enums in accountInfo (if it works)
  accountInfo: null | LiteAccountInfoReadonlyArray = null;
  loading = false;
  refreshing = false;
  repulling = false;
  orderHistory: ExtendedFormFreeReportHistory[] = [];
  accountMonitoringOptions: EnumerationItem[] = [];

  isLoading: boolean;
  accountMonitoringInterval = new Map();

  daysBackOptions: number[] = [ 30, 60, 90 ];

  protected loadingAccountInfo = false;
  protected assetTypes: EnumerationItem[] = [];

  constructor(
    private readonly _loanServicesService: LoanServicesService,
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _documentService: DocumentService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _enumsService: EnumerationService
  ) {
    this._enumsService.getMortgageEnumerations().subscribe((result) => {
      this.assetTypes = result[Constants.enumerations.assetTypes];
    });
  }

  ngOnInit() {
    this.isLoading = true;
    const combined = combineLatest([
      this._loanServicesService.getFormFreeAccountMonitoringOptions()
    ]);

    combined.subscribe({
      next: ([accountMonitoringOptions]) => {
        this.accountMonitoringOptions = Object.keys(accountMonitoringOptions).map(key => new EnumerationItem(key, accountMonitoringOptions[key]))

        const assetVerificationCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === ThirdPartyCredentialType.VOA);
        const formFreeCredential = assetVerificationCredentials?.find(el => el.vendorName === 'FormFree');
        const accountMonitoringIntervalValue = formFreeCredential?.thirdPartyKeyValuePairs.find(el => ['AccountMonitoringInterval'].includes(el.key)).value;
        this.orders.forEach(order => {
          this.accountMonitoringInterval[order.borrowerId.toString()] = accountMonitoringIntervalValue ? accountMonitoringIntervalValue : null;
        });
      },
      error: (error) => {
        this._notificationService.showError(`${error?.message || 'Unable to fetch System Level Info.'}`, 'System Level');
      }
    }).add(() => this.isLoading = false)
  }

  openImportAssetsDialog = (order: FormFreeHistory) => {
    const modalRef = this._modalService.open(ImportAssetsDialogComponent, {
      size: 'xl',
      backdrop: 'static',
      centered: true,
    });
    modalRef.componentInstance.order = order;
  }

  upgradeOrder = (order: FormFreeHistory) => {
    let req = new UpdateBorrowerVerificationSessionRequest();
    req.accountMonitoring = this.accountMonitoringInterval[order.borrowerId.toString()];
    req.daysBack = order.daysBack;
    req.requestType = BorrowerVerificationRequestType.full;

    this._loanServicesService.upgradeOrder(order.formFreeHistoryId, req).subscribe(
      (res: FormFreeHistory) => {
        this._notificationService.showSuccess(
          'Order upgraded successfully',
          'VOA Loan Service'
        );
        this.reloadHistory(true);
      },
      ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to upgrade order',
          'VOA Loan Service'
        );
      }
    );
  };

  generateReport = (order: FormFreeHistory) => {
    let req = new GenerateBorrowerVerificationReportRequest();
    req.applicationId = this.loanId;
    req.borrowerId = order.borrowerId;
    req.daysBack = order.daysBack;
    req.thirdPartyOrderId = order.accountChekOrderId;
    req.reportType = BorrowerVerificationReportType.Assets;

    this.repulling = true;
    this._loanServicesService
      .generatePdfReport(order.formFreeHistoryId, req)
      .pipe(
        finalize(() => {
          this.repulling = false;
        })
      )
      .subscribe(
        () => {
          this._notificationService.showSuccess(
            'Report repulled successfully',
            'VOA Loan Service'
          );
          this.reloadHistory(true);
        },
        ({ error }) => {
          this._notificationService.showError(
            error ? error.message : 'Unable to repull report',
            'VOA Loan Service'
          );
        }
      );

  };

  repullReport = (accountChekOrderId: string, reportId: string) => {
    if (!reportId || !accountChekOrderId) return;

    this.repulling = true;
    this._loanServicesService
      .repullPdfReport(accountChekOrderId, reportId)
      .pipe(
        finalize(() => {
          this.repulling = false;
        })
      )
      .subscribe(
        (res: string) => {
          this._notificationService.showSuccess(
            'Report repulled successfully',
            'VOA Loan Service'
          );
          this.reloadHistory(true);
        },
        ({ error }) => {
          this._notificationService.showError(
            error ? error.message : 'Unable to repull report',
            'VOA Loan Service'
          );
        }
      );
  };

  inviteBorrower = (order: FormFreeHistory) => {
    order['inviting'] = true;

    let req = new BorrowerVerificationUserEnrollmentRequest();
    req.firstName = order.firstName;
    req.lastName = order.lastName;
    req.last4Ssn = order.last4Ssn;
    req.email = order.email;
    req.applicationId = this.loanId;
    req.borrowerId = order.borrowerId;
    req.requestType = order.voaRequestType;
    req.accountMonitoringInterval = this.accountMonitoringInterval[order.borrowerId.toString()];
    req.daysBack = order.daysBack;
    req.integrationProvider = ThirdPartyIntegrationProvider.FormFree;

    this._loanServicesService.inviteBorrowerToVoaVoi(req)
      .pipe(finalize(() => order['inviting'] = false))
      .subscribe({
        next: (res) => {
          if (res.success) {
            this._notificationService.showSuccess(
              'Borrower invited successfully',
              'VOA Loan Service'
            );
            this.reloadHistory();
          } else {
            this._notificationService.showError(res.errorMessage, "Error occurred inviting borrower")
          }
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || err?.error || 'Unable to invite borrower',
            'VOA Loan Service'
          );
        }
      });
  };

  copySSOLink = (value) => {
    copyToClipboard(value);
  };

  copyTransferLink = (transactionId: string) => {
    this._loanServicesService.copyTransferLink(transactionId)
      .subscribe({
        next: (res: string) => {
          const value = res.trim();
          copyToClipboard(value);

          this._notificationService.showSuccess('Link copied', 'Success');
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || 'Unable to copy transfer link',
            'VOA Loan Service'
          );
        }
      });
  };

  getReport = (transactionId: string) => {
    this._loanServicesService.getLatestReport(transactionId).subscribe(
      (res) => {
        if (!res.reportData) return console.log('Report file is empty');
        if (res.reportData) {
          const fileUrl = this._documentService.convertBase64IntoPdf(
            res.reportData
          );
          window.open(fileUrl);
        }
      },
      ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to get latest report',
          'VOA Loan Service'
        );
      }
    );
  };

  getAccountInfo = (transactionId: string) => {
    this.loadingAccountInfo = true;
    this._spinnerService.show();
    this._loanServicesService
      .getAccountLiteInfo(transactionId)
      .pipe(
        finalize(() => {
          this.loadingAccountInfo = false;
          this._spinnerService.hide();
        })
      )
      .subscribe(
        (res) => {
          this.accountInfo = res || [];
          this.accountInfo.forEach(ai => {
            const matched = this.assetTypes.find(at => at.value == ai.accountType);
            ai["accountTypeDescription"] = matched ? matched.name : null;
          })
        },
        ({ error }) => {
          this._notificationService.showError(
            error ? error.message : 'Unable to get account info',
            'VOA Loan Service'
          );
        }
      );
  };

  private reloadHistory = (isExtended?: boolean) => {
    this._loanServicesService.getFormFreeHistory(this.loanId).subscribe(
      (history) => {
        this._notificationService.showSuccess(
          'History reloaded successfully',
          'VOA Loan Service'
        );
        this.prepareOrdersHistoryToView.emit(history || []);
        if (isExtended) {
          this.loadHistory.emit();
        }
      },
      ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to reaload history',
          'VOA Loan Service'
        );
      }
    );
  };
}
