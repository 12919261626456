import { Component, Injector, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import {
  ExtendedFormFreeHistory,
  ExtendedFormFreeReportHistory,
  FormFreeHistory,
  SystemLevel
} from 'src/app/models';
import { LoanService, LoanServicesService } from 'src/app/services/loan';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { NotificationService } from 'src/app/services/notification.service';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { ThirdPartyIntegrationProvider } from 'src/app/models/fee/fee.model';
import { BorrowerVerificationProductType } from 'src/app/models/voa/borrower-verification-account-link';
import { SystemLevelService } from 'src/app/services/system-level.service';

@Component({
  selector: 'digital-asset-verification',
  templateUrl: 'voa.component.html',
  styleUrls: ['./voa.component.scss']
})
export class VoaComponent extends ApplicationContextBoundComponent implements OnInit {
  loanId: number;

  borrowers: BorrowerDto[];
  orders: FormFreeHistory[];

  loadingData: boolean = false;
  isImportAssetsTabVisible: boolean = false;

  reportId: string = '';
  accountChekOrderId: string = '';
  selectedBorrowerFromHistory: string = '';

  orderHistory: ExtendedFormFreeReportHistory[] = [];
  systemLevel: SystemLevel;

  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _loanService: LoanService,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
  ) {
    super(injector);
  }

  ngOnInit() {

    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        this.applicationContext = context;
        if (context.application) {
          this.loanId = context.application.applicationId;
          this.getData();
        }
      });
    } else {
      this.loanId = this.applicationContext.application.applicationId;
      this.getData();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  prepareOrdersHistoryToView = (orders: FormFreeHistory[]) => {
    this.orders = [...orders];

    this.borrowers.forEach((borrower) => {
      const found = this.orders.some((item) => item.borrowerId == borrower.borrowerId);
      if (found) return;

      this.orders.push({
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        last4Ssn: borrower.last4Ssn,
        email: borrower.email,
        borrowerId: borrower.borrowerId,
        integrationProvider: ThirdPartyIntegrationProvider.FormFree,
        daysBack: null
      } as FormFreeHistory);
    });
  };

  loadHistory = () => {
    this._loanServicesService.getFormFreeReportHistory(this.loanId).subscribe(
      (reportHistory) => {
        this.extendOrderAndHistory(reportHistory);
      },
      ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to load history',
          'VOA Loan Service'
        );
      }
    );
  };

  getSelectedReportIds = (order: ExtendedFormFreeReportHistory) => {
    this.reportId = order.reportId;
    this.accountChekOrderId = order.accountChekOrderId;
    this.selectedBorrowerFromHistory = `${order.firstName} ${order.lastName}`;
    this.isImportAssetsTabVisible = true;
  };

  private extendOrderAndHistory = (
    reportHistory: ExtendedFormFreeReportHistory[]
  ) => {
    reportHistory?.forEach((hist) => {
      this.orders.forEach((order: ExtendedFormFreeHistory) => {
        if (order.formFreeHistoryId == hist.formFreeHistoryId) {
          hist.firstName = order.firstName;
          hist.lastName = order.lastName;
          order.reportId = hist.reportId;
          order.isRepullReport = hist.reportId && !hist.reportData;
        }
      });
    });
    this.orderHistory = reportHistory.filter(h => !h.reportType || h?.reportType == "Assets");
  };

  private getData = () => {
    this.loadingData = true;

    const combined = combineLatest([
      this._loanService.getBorrowers(this.loanId),
      this._loanServicesService.getFormFreeHistory(this.loanId),
      this._loanServicesService.getFormFreeReportHistory(this.loanId),
      this._systemLevelService.getSystemLevel()
    ]);

    combined.subscribe({
      next: ([borrowers, orders, reportHistory, systemLevel]) => {
        this.borrowers = borrowers || [];
        this.systemLevel = JSON.parse(JSON.stringify(systemLevel));

        const voaOrders = (orders || []).filter(o => !o.productTypes?.length || (o.productTypes.includes(BorrowerVerificationProductType.Assets)));
        this.prepareOrdersHistoryToView(voaOrders);

        this.extendOrderAndHistory(reportHistory || []);
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message || 'Unable to load data',
          'VOA Loan Service'
        );
      }
    }).add(() => this.loadingData = false)
  };
}
