<form class="p-3" #liabilityForm="ngForm" novalidate id="liabilityForm" name="liabilityForm">
  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="eventType">Account Owner</label>
      <ng-select width="100%" class="custom-ng-select" bindLabel="text" bindValue="id" name="owningBorrowerIds"
        [(ngModel)]="liability.owningBorrowerIds" [closeOnSelect]="false" [multiple]="true"
        (click)="accountTypeOrBorrowerChange()" [items]="possibleAccountOwners" required #owningBorrowerIds="ngModel"
        [ngClass]="{'is-invalid' : owningBorrowerIds && owningBorrowerIds.touched && owningBorrowerIds.invalid}">
      </ng-select>
      <div class="invalid-feedback">Please select a account owner.</div>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="typeOfLiability">Account Type</label>
      <select class="form-select" name="typeOfLiability" id="typeOfLiability" [(ngModel)]="liability.typeOfLiability"
        (change)="accountTypeOrBorrowerChange()">
        <option [value]="option.value" *ngFor="let option of liabilityTypeOptions">{{option.name}}</option>
      </select>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="creditor">Creditor</label>
      <input class="form-control" name="creditor" [(ngModel)]="liability.holderName">
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="accountNumber">Account #</label>
      <input class="form-control" name="accountNumber" [(ngModel)]="liability.accountNumber">
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12">
      <label class="mb-2" for="reoLiability">REO</label>
      <div
        *ngIf="liability.typeOfLiability != liabilityTypeHELOC && liability.typeOfLiability != liabilityTypeMortgageLoan">
        <span>—</span>
      </div>
      <div
        *ngIf="liability.typeOfLiability == liabilityTypeHELOC || liability.typeOfLiability == liabilityTypeMortgageLoan">
        <select class="form-select" name="reoLiability" id="reoLiability" [(ngModel)]="liability.reoId">
          <option [ngValue]="null">Select One</option>
          <option [ngValue]="option.value" *ngFor="let option of possibleReoAddresses">{{option.name}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="liability.typeOfLiability == liabilityTypeHELOC || liability.typeOfLiability == liabilityTypeMortgageLoan">
    <div class="col-md-6">
      <label for="lienPosition">Lien Position</label>
      <select class="form-select" name="lienPosition" id="lienPosition" [required]="true"
        [ngClass]="{'is-invalid' : lienPosition && lienPosition.touched && lienPosition.invalid}"
        [(ngModel)]="liability.lienPosition" #lienPosition="ngModel">
        <option *ngFor="let type of lienPositionTypes" [ngValue]="type.value">
          {{ type.name }}
        </option>
      </select>
      <div class="invalid-feedback">Please select lien position.</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2 d-block" for="moPayment">
        Monthly Payment
        <a title="Payoff Options" href="javascript: void(0);" class="dropdown-toggle pull-right"
          data-bs-toggle="dropdown" aria-expanded="false" *ngIf="liability.unpaidBalance">
          <i class="fa fa-calculator"></i> Deferment
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item w-100" role="button" *ngFor="let option of monthlyPaymentOptions;"
            (click)="setMonthlyPayment(liability, option.value)">
            {{option.displayText}}
          </a>
        </div>
      </label>
      <currency-input name="moPayment" [(ngModel)]="liability.monthlyPayment"></currency-input>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="monthsLeftToPay">Months Left To Pay</label>
      <input class="form-control" numeric [allowNegative]="false" name="monthsLeftToPay"
        [(ngModel)]="liability.monthsLeftToPay" number>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="balance">Unpaid Balance</label>
      <currency-input name="balance" [(ngModel)]="liability.unpaidBalance"
        (blur)="setMonthlyPaymentOptions(liability)"></currency-input>
    </div>
    <div class="col-md-6">
      <label class="mb-2 d-block" for="partialPayoffAmount">
        Payoffs

        <a title="Payoff Options" href="javascript: void(0);" class="dropdown-toggle pull-right"
          data-bs-toggle="dropdown" aria-expanded="false" *ngIf="liability.unpaidBalance">
          <i class="fa fa-calculator"></i> Set Payoff
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item w-100" role="button" (click)="setPayoffAmountToFull(liability)">Full</a>
          <a class="dropdown-item w-100" role="button" (click)="openPayoffDialog(liability)">Partial</a>
          <a class="dropdown-item w-100" role="button" (click)="setPayoffAmountToNone(liability)">None</a>
        </div>
      </label>
      <currency-input name="partialPayoffAmount" [(ngModel)]="liability.partialPayoffAmount"
        (ngModelChange)="checkPayoffAmount();"></currency-input>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="LiabilityWillBePaidOffWithCurrentAssets">Liability Will Be Paid Off With Current
        Assets?</label>
      <div class="form-check">
        <input class="form-check-input" name="LiabilityWillBePaidOffWithCurrentAssets" type="checkbox"
          id="LiabilityWillBePaidOffWithCurrentAssets"
          [(ngModel)]="liability.liabilityWillBePaidOffWithCurrentAssets" />
      </div>
    </div>
    <div class="col-md-6">

    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-12 text-end">
      <button class="btn btn-danger" (click)="onCancelClicked()">
        Cancel
      </button>
      <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
        Save
      </button>
    </div>
  </div>
</form>
