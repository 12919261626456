import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Select2OptionData } from 'ng-select2';
import { Utils } from 'src/app/core/services/utils';
import { Liability, RealEstateOwned } from 'src/app/models/mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import {
  EnterPayoffDialogComponent,
} from 'src/app/modules/urla/financial-information/liabilities/enter-payoff-dialog/enter-payoff-dialog.component';
import {
  MortgageCalculationService,
} from 'src/app/modules/urla/services/mortgage-calculation.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  QuickApplyFieldsConfigBoundComponent,
} from 'src/app/shared/components/quick-apply-fields-config-bound.component';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { Address } from '../../../../../../../models';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { map, race } from 'rxjs';
import { resetObjectTo } from '../../qa-fi-income/reset-object.to';

@Component({
  selector: 'qa-fi-liability-editor',
  templateUrl: './qa-fi-liability-editor.component.html',
  styleUrls: ['./qa-fi-liability-editor.component.scss']
})
export class QaFiLiabilityEditorComponent extends QuickApplyFieldsConfigBoundComponent implements OnInit, OnDestroy {

  @ViewChild('form') formElement: NgForm | undefined;

  @Input() liability: Liability;
  @Input() possibleAccountOwners: Array<Select2OptionData> = [];
  @Input() mode: 'create' | 'edit' = 'create';
  @Input() liabilityTypes: EnumerationItem[] = [];
  @Input() otherLiabilityTypes: EnumerationItem[] = [];
  @Input() realEstateOwned: RealEstateOwned[] = [];
  @Input() possibleReoAddresses: EnumerationItem[];
  @Input() lienPositionTypes: EnumerationItem[] = [];
  @Input() mortgageAppliedForTypes: EnumerationItem[] = [];
  @Input() states: EnumerationItem[];

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() update: EventEmitter<Liability> = new EventEmitter<Liability>();

  private _originalLiability: Liability;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false
  }

  readonly options: any = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' }
  }

  willBePaidOff: boolean;
  hoiEscrowed: boolean;

  payoffTypeFull: string;
  payoffTypePartial: string;

  liabilityTypeHELOC: string;

  liabilityTypeMortgageLoan: string;

  monthlyPaymentOptions = [];

  private _savingChangesSubscription?: Subscription;

  private readonly _save$ = new EventEmitter<void>();
  private readonly _destroyed$ = new EventEmitter<void>();

  constructor(
    injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _modalService: NgbModal,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._originalLiability = _.cloneDeep(this.liability);

    this.liabilityTypeHELOC = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC);
    this.liabilityTypeMortgageLoan = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan);
    this.payoffTypeFull = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
    this.payoffTypePartial = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial);

    if (this.liability.typeOfLiability == this.liabilityTypeHELOC ||
      this.liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
      this.populateReoAddresses();
    }
    this.setMonthlyPaymentOptions(this.liability);

    setTimeout(() => {
      this.subscribeToSavingChanges();
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private subscribeToSavingChanges(): void {
    this._savingChangesSubscription?.unsubscribe();
    const subscription = this._savingChangesSubscription = this.formElement.valueChanges.pipe(
      takeUntil(this._destroyed$),
    ).subscribe(() => {
      this.enqueueSave();
    });

    const cancelOrDestroy$ = race(
      this._destroyed$.pipe(map(() => true)),
      this.cancel.pipe(map(() => false)),
    ).pipe(
      tap((shouldSave) => {
        if (shouldSave) {
          // Prevent ExpressionChangedAfterItHasBeenCheckedError.
          setTimeout(() => {
            this.emitUpdate();
          });
        }
      }),
    );

    subscription.add(
      this._save$.pipe(
        takeUntil(cancelOrDestroy$),
        debounceTime(200),
      ).subscribe(() => {
        this.emitUpdate();
      }),
    );
  }

  private enqueueSave(): void {
    this._save$.next();
  }

  private emitUpdate(): void {
    this.update.emit(this.liability);
  }

  protected onClickCancel() {
    this.resetLiabilityChanges();

    this.cancel.emit();
  }

  private resetLiabilityChanges(): void {
    const liability = this.liability;
    if (liability == null) {
      console.error('Cannot reset changes as the liability is null.');
      return;
    }

    const originalLiability = this._originalLiability;
    if (originalLiability == null) {
      console.error('Cannot reset changes as the original liability is null.');
      return;
    }

    resetObjectTo(liability, originalLiability);
  }

  protected onClickClose() {
    if (this.onValidate()) {
      this.close.emit();
      return;
    }

    Swal.fire({
      title: 'Requested Fields Missing',
      text: 'There are requested fields that are not filled in. Do you want to proceed with missing data?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result: SweetAlertResult) => {
      if (result.value) {
        this.close.emit();
      }
    });
  }

  setMonthlyPaymentOptions = (liability: Liability) => {
    this.monthlyPaymentOptions = this._calculationService.setMonthlyPaymentOptions(liability);
    this.setPayoffTypeAndAmount(liability);
  }

  setMonthlyPayment = (liability: Liability, percent: number) => {
    this._calculationService.setMonthlyPayment(liability, percent);
  }

  accountTypeOrBorrowerChange = () => {
    if (this.liability.typeOfLiability == this.liabilityTypeHELOC ||
      this.liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
      this.populateReoAddresses();
    } else {
      this.liability.reoId = null;
    }
  }

  checkPayoffAmount = () => {
    if (this.liability.partialPayoffAmount >= this.liability.unpaidBalance) {
      this.liability.partialPayoffAmount = this.liability.unpaidBalance;
    }
    this.setPayoffTypeAndAmount(this.liability);
  }

  onValidate = (): boolean => {
    if (!this.formElement) return false;

    this.formElement.form.markAllAsTouched();
    if (!this.formElement.form.valid) return false;

    return true;
  }

  openPayoffDialog = (liability: Liability) => {
    const modalRef = this._modalService.open(EnterPayoffDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.partialPayoffAmount = liability.partialPayoffAmount;
    modalRef.componentInstance.unpaidBalance = liability.unpaidBalance;

    modalRef.result.then((result) => {
      if (!result) {
        result = 0;
      }
      liability.partialPayoffAmount = result;
      this.setPayoffTypeAndAmount(liability);
    });
  }

  setPayoffAmountToFull = (liability: Liability) => {
    liability.partialPayoffAmount = liability.unpaidBalance;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
  }

  setPayoffAmountToNone = (liability: Liability) => {
    liability.partialPayoffAmount = 0;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.None);
  }

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.liability["holderState"] = zipCode.state.toLowerCase();
      this.liability["holderCity"] = Utils.toTitleCase(zipCode.city);
      this.liability["holderZipCode"] = zipCode.zipcode;
    }
  }

  protected handleAddressChange(e: Partial<Address>) {
    const liability = this.liability;
    liability.holderAddress1 = ''; // to reset the last populated address.

    setTimeout(() => {
      liability.holderAddress1 = e.address1;
      liability.holderCity = e.city;
      liability.holderState = e.state;
      liability.holderZipCode = e.zipCode;
    }, 200);
  }

  private setPayoffTypeAndAmount = (liability: Liability) => {
    if (_.isUndefined(liability.partialPayoffAmount) || _.isUndefined(liability.unpaidBalance))
      return;

    if (liability.partialPayoffAmount == null || liability.partialPayoffAmount == 0) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.None);
    }
    else if (Number(liability.unpaidBalance) > Number(liability.partialPayoffAmount)) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial);
    }
    else if (Number(liability.partialPayoffAmount) == Number(liability.unpaidBalance)) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
    }
  }

  private populateReoAddresses = () => {
    let counter = 0;
    this.possibleReoAddresses = [];
    if (this.realEstateOwned && this.realEstateOwned.length > 0) {
      this.realEstateOwned.forEach(reo => {
        this.liability.owningBorrowerIds.forEach(borrowerId => {
          if (reo.owningBorrowerIds.map(id => Number(id)).indexOf(Number(borrowerId)) >= 0) {
            let exists = this.possibleReoAddresses.find(p => p.value == reo.reoId);
            if (!exists) {
              this.possibleReoAddresses.push(new EnumerationItem(reo.address1, reo.reoId));
            }
          } else {
            counter++;
          }
        });

      });
      if (counter == this.realEstateOwned.length * this.liability.owningBorrowerIds.length) {
        this.liability.reoId = null;
      }
    }
  }

}

