<loading-indicator *ngIf="isLoading"></loading-indicator>
<div class="card-body" [hidden]="isLoading">
  <div class="table-responsive">
    <table class="table mb-0 table-centered text-center">
      <thead class="table-light">
        <tr>
          <th>Borrower Name</th>
          <td>Order Type</td>
          <td>Report Days Back</td>
          <td>Monitoring</td>
          <td>Task Status</td>
          <td>Order Status</td>
          <td>Date</td>
          <td>SSO Link</td>
          <td>Transfer Link</td>
          <td>View Report</td>
          <td>View Account Data</td>
          <td>Action</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orders">
          <td>
            {{order.firstName + ' ' + order.lastName + ' (' + order.last4Ssn +')'}}
          </td>
          <td>
            <span *ngIf="order.voaRequestType"
              class="label label-{{order.voaRequestType == 'lite' ? 'warning' : 'success'}} label-order">
              {{order.voaRequestType}}
            </span>
          </td>
          <td>
            <select class="form-select" *ngIf="!order.isRepullReport" [name]="'daysBackOptions'"
              [(ngModel)]="order.daysBack">
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let item of daysBackOptions" [ngValue]="item">
                {{item}}
              </option>
            </select>

            <span *ngIf="order.isRepullReport" class="label">
              {{order.daysBack}}
            </span>
          </td>
          <td>
            <select *ngIf="!order.isRepullReport" class="form-select" [name]="'reportDays_'+ order.formFreeHistoryId"
              [(ngModel)]="accountMonitoringInterval[order.borrowerId.toString()]">
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let item of accountMonitoringOptions" [ngValue]=item.value>
                {{item.name}}
              </option>
            </select>

            <span *ngIf="order.isRepullReport" class="label">
              {{order.accountMonitoringInterval}}
            </span>
          </td>
          <td>
            {{order.taskStatus}}
          </td>
          <td>
            {{order.status}}
          </td>
          <td>
            {{order.dateInserted | date: 'short'}}
          </td>
          <td>
            <a href="javascript: void(0);" *ngIf="order.loginUrl" (click)="copySSOLink(order.loginUrl)">
              <i class='fa fa-md fa-clipboard'></i>
              Copy to Clipboard
            </a>
          </td>
          <td>
            <a href="javascript: void(0);" *ngIf="order.transactionId"
              (click)="copyTransferLink(order.transactionId)"><i class='fa fa-md fa-clipboard'></i>
              Generate and copy transfer link
            </a>
          </td>
          <td>
            <span *ngIf="refreshing || repulling" class="spinner-border spinner-border-sm text-primary"
              role="status"></span>
            <a *ngIf="order.latestReportId && !refreshing && !repulling" href="javascript: void(0);"
              (click)="getReport(order.transactionId)">
              <i class='fa fa-md fa-file-pdf'></i>
            </a>
          </td>
          <td>
            <a href="javascript: void(0);" *ngIf="order.transactionId && order.integrationProvider == 'FormFree'"
              (click)="getAccountInfo(order.transactionId)"><i class='fa fa-md fa-eye'></i></a>
            <a href="javascript: void(0);" *ngIf="order.accountChekOrderId && order.latestReportId"
              (click)="openImportAssetsDialog(order)"><i class='fa fa-md fa-file-import mx-2'></i></a>
          </td>
          <td>
            <a *ngIf="order.voaRequestType == 'lite' && !order.isRepullReport && order.status != 'canceled'" title="Upgrade Order"
              href="javascript:void(0);" (click)="upgradeOrder(order)" [class.disabled]="!order.daysBack || !accountMonitoringInterval[order.borrowerId.toString()]">
              <i class="fas fa-level-up-alt"></i>
            </a>

            <a *ngIf="order.voaRequestType == 'full' && !order.isRepullReport && order.status != 'canceled'" title="Generate Report"
              href="javascript:void(0);" (click)="generateReport(order)" [class.disabled]="!order.daysBack">
              <i class="fas fa-file-pdf"></i>
            </a>

            <a *ngIf="order.isRepullReport" [class.disabled]="!order.reportId || !order.accountChekOrderId"
              title=" Repull Report" href="javascript:void(0);"
              (click)="repullReport(order.accountChekOrderId, order.reportId)">
              <i class="fas fa-file-pdf"></i>
            </a>

            <button *ngIf="!order.formFreeHistoryId" class="btn btn-small btn-soft-primary"
              (click)="inviteBorrower(order)" [disabled]="order['inviting'] || !order.daysBack || !accountMonitoringInterval[order.borrowerId.toString()]">
              <span *ngIf="order['inviting']">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Inviting...
              </span>
              <span *ngIf="!order['inviting']">Invite To Complete</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template [ngIf]="accountInfo && loadingAccountInfo" [ngIfElse]="accountInfoPanel">
      <loading-indicator></loading-indicator>
    </ng-template>
    <ng-template #accountInfoPanel>
      <h4 *ngIf="accountInfo && accountInfo.length === 0">No Account Info Found</h4>
      <div *ngIf="accountInfo && accountInfo.length !== 0" class="accordion" id="accountDetailsAccordion">
        <h4>Account Details</h4>
        <div class="accordion-item" *ngFor="let item of accountInfo | groupBy: 'accountHolder'; let index = index">
          <h5 class="accordion-header m-0" id="heading-{{index}}">
            <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse-panel-' + index" aria-expanded="true"
              [attr.aria-controls]="'collapse-panel-' + index">
              <span>
                <b>{{item.key}}</b>
                ({{item.value.length}} Linked Account{{item.value.length === 1 ? "" : "s"}})
              </span>
            </button>
          </h5>
          <div id="collapse-panel-{{index}}" class="accordion-collapse collapse show"
            aria-labelledby="heading-{{index}}" data-bs-parent="#accountDetailsAccordion">
            <div class="accordion-body">
              <div class="accordion-sub-headers">
                <div class="accordion-sub-header">
                  <span><b>Institution Name</b></span>
                </div>
                <div class="accordion-sub-header">
                  <span><b>Account Type</b></span>
                </div>
                <div class="accordion-sub-header">
                  <span><b>Account #</b></span>
                </div>
                <div class="accordion-sub-header">
                  <span><b>Account Balance</b></span>
                </div>
                <div class="accordion-sub-header">
                  <span><b>Balance Date</b></span>
                </div>
              </div>
              <div *ngFor="let val of item.value">
                <div class="accordion-sub-headers">
                  <div class="accordion-sub-header">
                    <span>{{val.fiName}}</span>
                  </div>
                  <div class="accordion-sub-header">
                    <span>{{val['accountTypeDescription']}}</span>
                  </div>
                  <div class="accordion-sub-header">
                    <span>{{val.accountNumber}}</span>
                  </div>
                  <div class="accordion-sub-header">
                    <span>{{val.balance}}</span>
                  </div>
                  <div class="accordion-sub-header">
                    <span>{{val.balanceDate | date:'MM/dd/yyyy h:mma' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
