export class BorrowerVerificationAccountLink {
  thirdPartyLinkId: string;
  linkPublicToken: string;
  linkAccessToken: string;
  thirdPartyTransactionId: string;
  productType: BorrowerVerificationProductType;
  thirdPartyAccountProviderId: string;
}

export enum BorrowerVerificationProductType {
  Employment = "Employment",
  Income = "Income",
  DepositSwitch = "DepositSwitch",
  PLL = "PLL",
  Insurance = "Insurance",
  Transactions = "Transactions",
  Assets = "Assets"
}
