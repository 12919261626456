<div class="card">
  <div class="card-header">{{ mode === 'edit' ? 'Edit' : 'Add' }} Liability</div>
  <div class="card-body">
    <form #form="ngForm" novalidate id="liabilityForm" name="liabilityForm" class="w-100">
      <div class="row">
        <div class="col-md-6">


      <div class="row mb-2">
        <div class="col-md-12">
          <label for="eventType">Account Owner</label>
          <ng-select width="100%" class="custom-ng-select" bindLabel="text" bindValue="id" name="owningBorrowerIds"
                     [(ngModel)]="liability.owningBorrowerIds" [closeOnSelect]="false" [multiple]="true"
                     (click)="accountTypeOrBorrowerChange()" [items]="possibleAccountOwners" required
                     #owningBorrowerIds="ngModel"
                     [ngClass]="{'is-invalid' : owningBorrowerIds && owningBorrowerIds.touched && owningBorrowerIds.invalid}">
          </ng-select>
          <div class="invalid-feedback">Please select a account owner.</div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <label for="typeOfLiability">Account Type</label>
          <select class="form-select" name="typeOfLiability" id="typeOfLiability"
                  [(ngModel)]="liability.typeOfLiability" (change)="accountTypeOrBorrowerChange()"

                  [required]="quickApplyFieldsConfig['mortgage.liability.typeOfLiability'] == 'required' ||
              quickApplyFieldsConfig['mortgage.liability.typeOfLiability'] == 'requested'"
                  [ngClass]="{
              'invalid-requested' : accountType && accountType.invalid && accountType.errors && accountType.errors.required
                && quickApplyFieldsConfig['mortgage.liability.typeOfLiability'] == 'requested',
              'invalid-required' : accountType && accountType.invalid && accountType.errors && accountType.errors.required
                && quickApplyFieldsConfig['mortgage.liability.typeOfLiability'] == 'required'
            }"
                  #accountType="ngModel">
            <option [ngValue]="null">-- Select One --</option>
            <optgroup label="Credits, Debts, and Leases">
              <option *ngFor="let type of liabilityTypes" [ngValue]="type.value">
                {{ type.name }}
              </option>
            </optgroup>
            <optgroup label="Other Liabilities and Expenses">
              <option *ngFor="let type of otherLiabilityTypes" [ngValue]="type.value">
                {{ type.name }}
              </option>
            </optgroup>
          </select>
          <div class="invalid-feedback">Please select a account type.</div>
        </div>
      </div>
      <div class="row mb-2" *ngIf="liability.typeOfLiability == liabilityTypeHELOC">
        <div class="col-md-12">
          <label for="lienPosition">Lien Position</label>
          <select class="form-select" name="lienPosition" id="lienPosition"
                  [required]="quickApplyFieldsConfig['mortgage.liability.lienPosition'] == 'required' ||
              quickApplyFieldsConfig['mortgage.liability.lienPosition'] == 'requested'"
                  [ngClass]="{
              'invalid-requested' : lienPosition && lienPosition.invalid && lienPosition.errors && lienPosition.errors.required
                && quickApplyFieldsConfig['mortgage.liability.lienPosition'] == 'requested',
              'invalid-required' : lienPosition && lienPosition.invalid && lienPosition.errors && lienPosition.errors.required
                && quickApplyFieldsConfig['mortgage.liability.lienPosition'] == 'required'
            }"
                  [(ngModel)]="liability.lienPosition" #lienPosition="ngModel">
            <option *ngFor="let type of lienPositionTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <label for="reoLiability">REO</label>
          <div
            *ngIf="liability.typeOfLiability != liabilityTypeHELOC && liability.typeOfLiability != liabilityTypeMortgageLoan">
            <span>—</span>
          </div>
          <div
            *ngIf="liability.typeOfLiability == liabilityTypeHELOC || liability.typeOfLiability == liabilityTypeMortgageLoan">
            <select class="form-select" name="reoLiability" id="reoLiability" [(ngModel)]="liability.reoId"
              #liabilityReo="ngModel"
              [required]="quickApplyFieldsConfig['mortgage.liability.reoId'] == 'required' ||
                quickApplyFieldsConfig['mortgage.liability.reoId'] == 'requested'"
              [ngClass]="{
                'invalid-requested' : liabilityReo && liabilityReo.invalid && liabilityReo.errors && liabilityReo.errors.required
                  && quickApplyFieldsConfig['mortgage.liability.mortgageType'] == 'requested',
                'invalid-required' : liabilityReo && liabilityReo.invalid && liabilityReo.errors && liabilityReo.errors.required
                  && quickApplyFieldsConfig['mortgage.liability.mortgageType'] == 'required'
              }">
              <option [ngValue]="null">Select One</option>
              <option [ngValue]="option.value" *ngFor="let option of possibleReoAddresses">{{option.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-2" *ngIf="liability.typeOfLiability == liabilityTypeMortgageLoan">
        <div class="col-md-6 pe-0">
          <label for="mortgageType">Mortgage Type</label>
          <select class="form-select custom-input-left-side" name="mortgageType" id="mortgageType"
                  [required]="quickApplyFieldsConfig['mortgage.liability.mortgageType'] == 'required' ||
              quickApplyFieldsConfig['mortgage.liability.mortgageType'] == 'requested'"
                  [ngClass]="{
              'invalid-requested' : typeOfMortgage && typeOfMortgage.invalid && typeOfMortgage.errors && typeOfMortgage.errors.required
                && quickApplyFieldsConfig['mortgage.liability.mortgageType'] == 'requested',
              'invalid-required' : typeOfMortgage && typeOfMortgage.invalid && typeOfMortgage.errors && typeOfMortgage.errors.required
                && quickApplyFieldsConfig['mortgage.liability.mortgageType'] == 'required'
            }"
                  [(ngModel)]="liability.mortgageType" #typeOfMortgage="ngModel">
            <option *ngFor="let type of mortgageAppliedForTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6 ps-0">
          <label for="lienPosition">Lien Position</label>
          <select class="form-select custom-input-right-side" name="lienPosition" id="lienPosition"
                  [required]="quickApplyFieldsConfig['mortgage.liability.lienPosition'] == 'required' ||
              quickApplyFieldsConfig['mortgage.liability.lienPosition'] == 'requested'"
                  [ngClass]="{
              'invalid-requested' : lienPosition && lienPosition.invalid && lienPosition.errors && lienPosition.errors.required
                && quickApplyFieldsConfig['mortgage.liability.lienPosition'] == 'requested',
              'invalid-required' : lienPosition && lienPosition.invalid && lienPosition.errors && lienPosition.errors.required
                && quickApplyFieldsConfig['mortgage.liability.lienPosition'] == 'required'
            }"
                  [(ngModel)]="liability.lienPosition" #lienPosition="ngModel">
            <option *ngFor="let type of lienPositionTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-6 pe-0">
          <label for="balance">Unpaid Balance</label>
          <currency-input name="balance"
                          [required]="quickApplyFieldsConfig['mortgage.liability.unpaidBalance'] == 'required' ||
              quickApplyFieldsConfig['mortgage.liability.unpaidBalance'] == 'requested'"
                          [ngClass]="{
              'invalid-requested' : unpaidBalance && unpaidBalance.invalid && unpaidBalance.errors && unpaidBalance.errors.required
                && quickApplyFieldsConfig['mortgage.liability.unpaidBalance'] == 'requested',
              'invalid-required' : unpaidBalance && unpaidBalance.invalid && unpaidBalance.errors && unpaidBalance.errors.required
                && quickApplyFieldsConfig['mortgage.liability.unpaidBalance'] == 'required'
            }"
                          [(ngModel)]="liability.unpaidBalance" #unpaidBalance="ngModel" class="custom-input-left-side"
                          (blur)="setMonthlyPaymentOptions(liability)"></currency-input>
        </div>
        <div class="col-md-6 ps-0">
          <label class="d-block o-1" for="moPayment">
            <span class="o-0_7">Monthly Payment</span>
            <a title="Payoff Options" href="javascript: void(0);" class="dropdown-toggle pull-right o-0_7"
              data-bs-toggle="dropdown" aria-expanded="false" *ngIf="liability.unpaidBalance">
              <i class="fa fa-calculator"></i> Deferment
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item w-100" role="button" *ngFor="let option of monthlyPaymentOptions;"
                (click)="setMonthlyPayment(liability, option.value)">
                {{option.displayText}}
              </a>
            </div>
          </label>
          <currency-input name="moPayment" class="custom-input-right-side" [(ngModel)]="liability.monthlyPayment"></currency-input>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <label for="partialPayoffAmount">Payoffs</label>
          <currency-input name="partialPayoffAmount"
                          [required]="quickApplyFieldsConfig['mortgage.liability.partialPayoffAmount'] == 'required' ||
              quickApplyFieldsConfig['mortgage.liability.partialPayoffAmount'] == 'requested'"
                          [ngClass]="{
              'invalid-requested' : partialPayoffAmount && partialPayoffAmount.invalid && partialPayoffAmount.errors && partialPayoffAmount.errors.required
                && quickApplyFieldsConfig['mortgage.liability.partialPayoffAmount'] == 'requested',
              'invalid-required' : partialPayoffAmount && partialPayoffAmount.invalid && partialPayoffAmount.errors && partialPayoffAmount.errors.required
                && quickApplyFieldsConfig['mortgage.liability.partialPayoffAmount'] == 'required'
            }"
                          [(ngModel)]="liability.partialPayoffAmount" #partialPayoffAmount="ngModel"
                          (ngModelChange)="checkPayoffAmount();"></currency-input>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-6 pe-0">
          <label for="monthsLeftToPay">Remaining Months</label>
          <input class="form-control custom-input-left-side" numeric [allowNegative]="false" name="monthsLeftToPay"
                 [(ngModel)]="liability.monthsLeftToPay" number>
        </div>
        <div class="col-md-6 ps-0">
          <label for="accountNumber">Account #</label>
          <input class="form-control custom-input-right-side" name="accountNumber" [(ngModel)]="liability.accountNumber"
          [ngClass]="{'is-invalid': accountNumber && accountNumber.touched && accountNumber.invalid}" #accountNumber="ngModel"
          [required]="quickApplyFieldsConfig['mortgage.liability.accountNumber'] == 'required' ||
              quickApplyFieldsConfig['mortgage.liability.accountNumber'] == 'requested'">
          <div class="invalid-feedback">Please enter a account number.</div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-6 pe-0">
          <label for="mortgageType">Account Status</label> <!-- No Model -->
          <select class="form-select custom-input-left-side" name="accountStatus" id="accountStatus">
            <option  [ngValue]="'Open'">
              Open
            </option>
          </select>
        </div>
        <div class="col-md-6 ps-0">
          <label for="lienPosition">Current Rating Type</label>
          <select class="form-select custom-input-right-side" name="currentRatingType" id="currentRatingType"
            >
            <option [ngValue]="'asAgreed'">
              As Agreed
            </option>
          </select>
        </div>
      </div>
      </div>
      <div class="col-md-6">
      <div class="row mb-2">
        <div class="col-md-12">
          <label for="creditor">Creditor</label>
          <input class="form-control" name="creditor" [(ngModel)]="liability.holderName"
          [ngClass]="{'is-invalid': holderName && holderName.touched && holderName.invalid}" #holderName="ngModel"
          [required]="quickApplyFieldsConfig['mortgage.liability.holderName'] == 'required' ||
              quickApplyFieldsConfig['mortgage.liability.holderName'] == 'requested'">
          <div class="invalid-feedback">Please enter a creditor name.</div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-xs-6 col-sm-8 pe-0">
            <label for="liability_address1-editor-{{ liability.liabilityId }}">Creditor Address</label>
          <address-autocomplete-input
            (addressChange)='handleAddressChange($event)'
            [(ngModel)]='liability.holderAddress1'
            class='custom-input-left-side'
            id='liability_address1-editor-{{ liability.liabilityId }}'
            name='liability_address1-editor-{{ liability.liabilityId }}'
            placeholder='Address'
          ></address-autocomplete-input>
        </div>
        <div class="col-xs-6 col-sm-4 ps-0">
            <label for="liability_address2-editor-{{ liability.liabilityId }}"></label>
            <input id="liability_address2-editor-{{ liability.liabilityId }}"
                   name="liability_address2-editor-{{ liability.liabilityId }}" type="text"
                   class="form-control custom-input-right-side" placeholder="Unit / Apt"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-xs-6 col-sm-5 pe-0">
            <input id="propertyCity" name="propertyCity" type="text" placeholder="City"
                class="form-control custom-input-left-side" [(ngModel)]="liability.holderCity" />
        </div>
        <div class="col-xs-6 col-sm-3 px-0">
            <select id="propertyState" name="propertyState" placeholder="State"
                class="form-select custom-input-left-side custom-input-right-side" placeholder="State"
                [(ngModel)]="liability.holderState">
                <option [ngValue]="null"></option>
                <option *ngFor="let state of states" [ngValue]="state.value">
                    {{ state.name }}
                </option>
            </select>
        </div>
        <div class="col-xs-6 col-sm-4 ps-0">
            <zip-code-input [(ngModel)]="liability.holderZipCode" [name]="'propertyZip'"
                            class="custom-input-left-side custom-input-right-side" placeholder="Zip Code"
                            (blur)="onZipCodeRelatedInfoChanged($event)" [required]="false"
                            (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
            </zip-code-input>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 pe-0">
          <label for="holderEmail">Email</label>
          <input class="form-control custom-input-left-side" name="holderEmail" type="email" trim
           email />
        </div>
        <div class="col-md-6 ps-0">
          <label for="holderPhone">Work Phone</label>
          <input class="form-control custom-input-right-side" [mask]="'(000) 000-0000'"
              [showMaskTyped]="true" name="workPhone" [(ngModel)]="liability.holderPhone"
              [ngModelOptions]="{standalone: true}" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-4">
          <div class="form-check dropdown-toggle" data-bs-toggle="dropdown">
            <input type="checkbox" class="form-check-input " id="willBePaidOff" name="willBePaidOff"
              [checked]="liability.payoffType == payoffTypeFull ||  liability.payoffType == payoffTypePartial">
            <label class="form-check-label" for="willBePaidOff">Will be paid off</label>
          </div>
          <div class="dropdown-menu">
            <a class="dropdown-item w-100" role="button" (click)="setPayoffAmountToFull(liability)">Full</a>
            <a class="dropdown-item w-100" role="button" (click)="openPayoffDialog(liability)">Partial</a>
            <a class="dropdown-item w-100" role="button" (click)="setPayoffAmountToNone(liability)">None</a>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="derogatory" name="derogatory"
              [(ngModel)]="liability.derogatoryDataIndicator">
            <label class="form-check-label" for="derogatory">Derogatory</label>
          </div>
        </div>
      </div>
      <div class="row mb-2" style="height: 33.5px;">
        <div class="col-md-4 d-flex align-items-center">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="omitted" name="omitted"
              [(ngModel)]="liability.isExcluded">
            <label class="form-check-label" for="omitted">Omitted</label>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-between" *ngIf="liability.typeOfLiability == liabilityTypeMortgageLoan">
          <div class="form-check col-6 d-block">
            <input type="checkbox" class="form-check-input" id="hoiEscrowed" name="hoiEscrowed"
              [(ngModel)]="hoiEscrowed">
            <label class="form-check-label" for="hoiEscrowed">HOI Escrowed</label>
          </div>
          <currency-input *ngIf="!hoiEscrowed"
              name="hoiAmount" class="me-4 col-6">
            </currency-input>
        </div>
      </div>
      <div class="row mb-2" style="height: 33.5px;" *ngIf="liability.typeOfLiability == liabilityTypeMortgageLoan">
        <div class="col-md-4 d-flex align-items-center">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="reSubordinate" name="reSubordinate"
              [(ngModel)]="liability.isResubordinate">
            <label class="form-check-label" for="reSubordinate">Re-Subordinate</label>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-between">
          <div class="form-check col-6 d-block">
            <input type="checkbox" class="form-check-input" id="propertyTaxesEscrowed" name="propertyTaxesEscrowed"
              [(ngModel)]="liability.mortgageMonthlyPaymentIncludesTaxesAndInsurance">
            <label class="form-check-label" for="propertyTaxesEscrowed">Property Taxes Escrowed</label>
          </div>
          <currency-input *ngIf="!liability.mortgageMonthlyPaymentIncludesTaxesAndInsurance"
              name="propertyTaxesAmount" class="me-4 col-6">
          </currency-input>
        </div>
      </div>
      <div class="row mb-3 mt-2" *ngIf="liability.isExcluded">
        <div class="col-md-12">
          <label for="reasonForOmission">Reason for Omission</label>
          <input class="form-control" name="reasonForOmission" type="textarea"
            placeholder="Description">
        </div>
      </div>
    </div>
    </div>
    </form>
  </div>
  <div class="card-footer d-flex justify-content-end align-items-stretch gap-2">
    <qa-cancel-button (qaClick)='onClickCancel()'></qa-cancel-button>

    <qa-ok-button (qaClick)='onClickClose()'></qa-ok-button>
  </div>
</div>
