import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize} from 'rxjs/operators';
import {RoleList, ThirdPartyCredential, ThirdPartyKeyValue} from '../../../../../../models';
import {NotificationService} from '../../../../../../services/notification.service';
import {SystemLevelService} from '../../../../../../services/system-level.service';
import {ApplicationContextBoundComponent} from '../../../../../../shared/components';

@Component({
  selector: 'title-ordering-modal',
  templateUrl: './title-ordering-modal.component.html',
  styleUrls: ['./title-ordering-modal.component.scss'],
})
export class TitleOrderingModalComponent
  extends ApplicationContextBoundComponent
  implements OnInit
{
  @ViewChild('credentialForm') credentialForm: NgForm | undefined;
  @Input() showLenderLoadingError: boolean;
  @Input() roles: RoleList;
  @Input() credential: ThirdPartyCredential;
  @Input() lodasoftLenders: any[];
  @Input() titleLenders: any[];

  selectedLenders: any[] = [];
  selectedRoles: number[] = [];

  isSaving: boolean;

  authUrl: string = 'https://softpro.oktapreview.com/oauth2/aus12mqbmbyaz6DOY0h8/v1/token';
  routingNumber: string;
  apiVersion: string = '3.0';
  branchAltaId: string;
  providerReferenceName: string;
  lenderLinkedId: string;
  orderUpdateEmailTo: string;
  protected customEvents: CustomEvent[] = [];

  constructor(
    injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.credential.vendorName === 'SoftPro') {
      this.setValueToModel('CustomEvents', 'customEvents', (val: string) => JSON.parse(val), []);
    }
    if (this.credential.vendorName == 'StatesTitle') {
      this.setValueToModel(
        'ContactRoles',
        'selectedRoles',
        (val: string) => {
          return val
            .split(',')
            .map(el => {
              const role = this.roles.find(e => e.roleId === parseInt(el));
              return role ? role.roleId : null;
            })
            .filter(id => !!id);
        },
        []
      );

      this.setValueToModel(
        'LenderMap',
        'selectedLenders',
        (val: string) => {
          return val.split(',').map(el => {
            const ids = el.split(':');
            return {
              lodasoftLenderId: ids[0],
              thirdPartyLenderId: ids[1],
            };
          });
        },
        []
      );
    } else {
      this.credential.url =
        this.credential.url || 'https://fnf.softprohq.com/api/vendor/transactions';

      this.setValueToModel(
        'AuthUrl',
        'authUrl',
        null,
        'https://softpro.oktapreview.com/oauth2/aus12mqbmbyaz6DOY0h8/v1/token'
      );
      this.setValueToModel('RoutingNumber', 'routingNumber');
      this.setValueToModel('ApiVersion', 'apiVersion', null, '3.0');
      this.setValueToModel('BranchAltaID', 'branchAltaId');
      this.setValueToModel('ProviderReferenceName', 'providerReferenceName');
      this.setValueToModel('LenderLinkedID', 'lenderLinkedId');
      this.setValueToModel('OrderUpdateEmailTo', 'orderUpdateEmailTo');
    }
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) {
      return;
    }

    if (this.credential.vendorName === 'SoftPro') {
      this.setValueToKvp('CustomEvents', JSON.stringify(this.customEvents));
    }

    if (this.credential.vendorName == 'StatesTitle') {
      this.setValueToKvp('ContactRoles', this.selectedRoles.join());
      this.setValueToKvp(
        'LenderMap',
        this.selectedLenders.map(el => `${el.lodasoftLenderId}:${el.thirdPartyLenderId}`).join()
      );
    } else if (this.credential.vendorName == 'SoftPro') {
      this.setValueToKvp('AuthUrl', this.authUrl);
      this.setValueToKvp('RoutingNumber', this.routingNumber);
      this.setValueToKvp('ApiVersion', this.apiVersion);
      this.setValueToKvp('BranchAltaID', this.branchAltaId);
      this.setValueToKvp('ProviderReferenceName', this.providerReferenceName);
      this.setValueToKvp('LenderLinkedID', this.lenderLinkedId);
      this.setValueToKvp('OrderUpdateEmailTo', this.orderUpdateEmailTo);
    }

    this.credential.companyId = this.applicationContext.userPermissions.companyId;

    this.isSaving = true;
    this._systemLevelService
      .saveCredential(this.credential)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          this.activeModal.close(res);
        },
        error: error => {
          this._notificationService.showError(
            `${error ? error.message : 'Unable to save.'}`,
            'System Level'
          );
        },
      });
  }

  addLender(): void {
    this.selectedLenders.push({
      lodasoftLenderId: '',
      thirdPartyLenderId: '',
    });
  }

  protected addCustomEvent(): void {
    this.customEvents.push({
      name: '',
      requestDocument: false,
    });
  }

  protected removeCustomEvent(index: number): void {
    this.customEvents.splice(index, 1);
  }

  private setValueToKvp = (key: string, val: string) => {
    let keyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key == key);
    if (!keyValuePair) {
      keyValuePair = new ThirdPartyKeyValue(key, val);
      this.credential.thirdPartyKeyValuePairs.push(keyValuePair);
    } else {
      keyValuePair.value = val;
    }
  };

  private setValueToModel<T = string>(
    key: string,
    fieldName: string,
    parsingFunction?: (val: string) => T,
    defaultValue: T = null
  ) {
    const keyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key == key);
    if (keyValuePair?.value) {
      this[fieldName] = parsingFunction ? parsingFunction(keyValuePair.value) : keyValuePair.value;
    } else {
      this[fieldName] = defaultValue;
    }
  }
}

interface CustomEvent {
  name: string;
  requestDocument: boolean;
}
